import React, { useRef, useEffect } from "react";
import {
  applyPolyfills,
  defineCustomElements,
} from "@microblink/blinkid-in-browser-sdk/ui/loader";
import { getFirstNonEmptyValue, sendMessage } from "../lib/utils";

function DocumentScanner({ setData, setImages }) {
  const el = useRef(null);

  useEffect(() => {
    applyPolyfills().then(() => {
      defineCustomElements().then(() => {
        // el.current.licenseKey = process.env.REACT_APP_MICROBLINK_KEY_ON_WEB;
        // el.current.licenseKey = process.env.REACT_APP_MICROBLINK_KEY_LOCAL;
        el.current.licenseKey = process.env.REACT_APP_MICROBLINK_KEY_ON_WEB_NO_AWS;
        el.current.recognizers = ["BlinkIdMultiSideRecognizer"];
        el.current.recognizerOptions = {
          BlinkIdMultiSideRecognizer: {
            returnFullDocumentImage: true,
            returnEncodedFullDocumentImage: true,
            fullDocumentImageDpi: 400,
            saveCameraFrames: true,
            paddingEdge: 0.02,
            returnSignatureImage: true,
            returnFaceImage: true,
            // "includeSuccessFrame": true
          },
        };
        el.current.saveCameraFrames = true;

        // Engine location depends on the actual location of WebAssembly resources
        el.current.engineLocation = window.location.origin + "/resources";
        el.current.allowHelloMessage = false;
        el.current.scanFromImage = false;
        el.current.includeSuccessFrame = true;
        el.current.iconCameraScanningCompleted =
          "data:image/svg+xml;base64,PHN2ZyBpZD0ic3ZnIiB2aWV3Qm94PSIxNSAyMCAxNjUgMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHRleHQgeD0iMjAiIHk9IjM1IiBjbGFzcz0ic21hbGwiPkVzcGVyZSB1biBtb21lbnRvLi4uPC90ZXh0Pgo8L3N2Zz4=";
        // Aqui se puede usar internalizacion como i18n
        // i18n requiere instalar librerias extras
        el.current.translations = {
          "action-alt-camera": "Camara del dispositivo",
          "action-alt-gallery": "Desde la galeria",
          "action-message": "Scanear documento",
          "action-message-camera": "Camara del dispositivo",
          "action-message-camera-disabled": "Camara deshabilitada",
          "action-message-camera-not-allowed": "Camera not allowed",
          "action-message-camera-in-use": "Camara en uso",
          "action-message-image": "Desde la galeria",
          "action-message-image-not-supported": "No soportado",
          "camera-disabled": "Camara deshabilitada",
          "camera-not-allowed": "No se puede acceder a la camara.",
          "camera-in-use": "La camara esta siendo usada por otra aplicacion",
          "camera-generic-error": "No se puede acceder a la camara.",
          "camera-feedback-scan-front": [
            "Escanear el frontal",
            "de un documento",
          ],
          "camera-feedback-scan-back": [
            "Escanear el posterior",
            "de un documento",
          ],
          "camera-feedback-flip": "Dar vuelta al reverso",
          "camera-feedback-barcode-message": "Escanear el barcode",
          "camera-feedback-move-farther": "Alejarse",
          "camera-feedback-move-closer": "Acercarse",
          "camera-feedback-adjust-angle": "Ajuste el angulo",
          "camera-feedback-barcode": [
            "Acerque el barcode",
            "y mantengalo centrado",
          ],
          "initialization-error":
            "Fallo al cargar el componente. Intente con otro dispositivo o actualice su navegador.",
          "process-image-box-first": "Imagen frontal",
          "process-image-box-second": "Imagen posterior",
          "process-image-box-add": "Agregar imagen",
          "process-image-upload-cta": "Cargar",
          "process-image-message": "Un momento.",
          "process-image-message-inline": "Procesando",
          "process-image-message-inline-done": "Procesamiento completo",
          "process-api-message": "Un momento",
          "process-api-retry": "Reintentar",
          "feedback-scan-unsuccessful-title": "Escaneo fallido",
          "feedback-scan-unsuccessful":
            "No pudimos reconocer su documento. Por favor intente nuevamente.",
          "feedback-error-generic":
            "Whoops, no funciono. Por favor intentelo nuevamente.",
          "check-internet-connection": "Revise su conexion a internet.",
          "network-error": "Error de conexion.",
          "scanning-not-available": "Escaner no disponible.",
          "modal-window-close": "Cerrar",
        };

        el.current.addEventListener("scanSuccess", (ev) => {
          // console.log(ev.detail);
          let data = ev.detail.recognizer;
          let result = {
            documentNumber: getFirstNonEmptyValue(data.documentNumber),
            firstName: getFirstNonEmptyValue(data.firstName),
            lastName: getFirstNonEmptyValue(data.lastName),
            personalIdNumber: getFirstNonEmptyValue(data.personalIdNumber),
            placeOfBirth: getFirstNonEmptyValue(data.placeOfBirth),
            issuingAuthority: getFirstNonEmptyValue(data.issuingAuthority),
            dateOfBirth: {
              day: data.dateOfBirth.day,
              month: data.dateOfBirth.month,
              year: data.dateOfBirth.year,
              originalString: getFirstNonEmptyValue(
                data.dateOfBirth.originalString
              ),
              successfullyParsed: data.dateOfBirth.successfullyParsed,
              empty: data.dateOfBirth.empty,
            },
            dateOfIssue: {
              day: data.dateOfIssue.day,
              month: data.dateOfIssue.month,
              year: data.dateOfIssue.year,
              originalString: getFirstNonEmptyValue(
                data.dateOfIssue.originalString
              ),
              successfullyParsed: data.dateOfIssue.successfullyParsed,
              empty: data.dateOfIssue.empty,
            },
            dateOfExpiry: {
              day: data.dateOfExpiry.day,
              month: data.dateOfExpiry.month,
              year: data.dateOfExpiry.year,
              originalString: getFirstNonEmptyValue(
                data.dateOfExpiry.originalString
              ),
              successfullyParsed: data.dateOfExpiry.successfullyParsed,
              empty: data.dateOfExpiry.empty,
            },
            country: data.classInfo.countryName,
            countryCode: data.classInfo.isoAlpha2CountryCode,
            sex: getFirstNonEmptyValue(data.sex),
            address: getFirstNonEmptyValue(data.address),
            maritalStatus: getFirstNonEmptyValue(data.maritalStatus),
            nationality: getFirstNonEmptyValue(data.nationality),
            documentType: data.classInfo.documentType,
          };
          // if barcode object is not empty then append barcode to result
          data.barcode && (result.barcode = data.barcode);
          data.mrz && (result.mrz = data.mrz);

          console.log(result)

          let images = {
            frontImage: data.fullDocumentFrontImage,
            frontCameraFrame: data.frontCameraFrame,
            backImage: data.fullDocumentBackImage,
            backCameraFrame: data.backCameraFrame,
            faceImage: data.faceImage,
          };

          console.log(JSON.stringify(data.classInfo));
          console.log(JSON.stringify(result));

          setData(result);
          setImages(images);
        });

        el.current.addEventListener("scanError", (ev) => {
          console.log("Error al escanear documento");
          sendMessage("scanError", {
            data: "Error al escanear documento",
            message: "FAILURE",
          });
        });

        el.current.addEventListener("fatalError", (ev) => {
          console.log("fatal error");
          sendMessage("scanError", {
            data: "Error al escanear documento",
            message: "FAILURE",
          });
        });
      });
    });
  }, [setData, setImages]);

  return <blinkid-in-browser ref={el}></blinkid-in-browser>;
}

export default DocumentScanner;
